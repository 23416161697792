import React from 'react';
import { graphql } from 'gatsby';
import { LayoutPrivacyPolicy } from '../components/layout/LayoutPrivacyPolicy';
import Seo from '../components/layout/SEO';

const PrivacyPolicy = () => {


	return (

		<LayoutPrivacyPolicy>
			<Seo
				title={"Privacy Policy | Autoscuola Faro, Pisa"}
				description={"Leggi la Privacy Policy di Autoscuola Faro con sede a Pisa."}
				keywords={[
					"Privacy Policy Autoscuola Faro Pisa",
					"Informativa sulla Privacy Autoscuola Faro",
					"Trattamento dei Dati Personali",
					"Regolamento sulla Privacy"
				]}
			/>

			<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
				<h2 className="font-roboto text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl sm:leading-none mb-6">
					Privacy Policy
				</h2>
				<h2 className="font-roboto text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none mb-6">
					INFORMATIVA AI SENSI DEL D.L .196/03 E DEL REGOLAMENTO EUROPEO 679/2016 (GDPR)
				</h2>
				<p className="mb-12 text-base text-gray-700">
					Questa informativa è resa esclusivamente per il sito www.autoscuolafaro.it e non anche per altri siti web
				</p>

				<div className="py-8">
					<div className="flex flex-col">

						{/* Titolare */}
						<div className="mb-6 lg:mb-4 lg:pr-5">
							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-3xl  text-gray-900 sm:text-4xl sm:leading-none">
									Titolare del trattamento
								</h2>
							</div>
							<div className="">
								<p className="mb-4 text-base text-gray-700">
									Titolare del trattamento è il sig. Salvatore Simonelli,
									titolare della ditta Autoscuola Faro Snc con sede in Via Emilia, 2 – Pisa.
									Il titolare potrà essere contattato mediante mail all’indirizzo <a href="mailto:info@autoscuolafaro.it">info@autoscuolafaro.it</a>.
								</p>
							</div>
						</div>



						{/* TIPOLOGIA DATI RACCOLTI */}
						<div className="mb-6 lg:pr-5">
							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-3xl  text-gray-900 sm:text-4xl sm:leading-none">
									Tipologia di dati raccolti
								</h2>
							</div>
							<p className="mb-4 text-base text-gray-700">
								Fra i Dati Personali raccolti da questo Sito Web, in modo autonomo o tramite terze parti, ci sono:
								cookie, dati di utilizzo, nome, cognome, email.
								Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate di questa privacy
								policy o mediante specifici testi informativi visualizzati prima della raccolta dei dati stessi.
								I Dati Personali possono essere liberamente forniti dall’Utente o, nel caso di Dati di Utilizzo,
								raccolti automaticamente durante l’uso di questo Sito Web mediante software di terze parti (es. Google Analytics).
								Se non diversamente specificato, tutti i Dati richiesti da questo Sito Web sono obbligatori.
								Se l’Utente rifiuta di comunicarli, potrebbe essere impossibile per questo Sito Web fornire il Servizio.
								Nei casi in cui questo Sito Web indichi alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati,
								senza che ciò abbia alcuna conseguenza sulla disponibilità del Servizio o sulla sua operatività.
								Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono incoraggiati a contattare il Titolare.
								L’eventuale utilizzo di Cookie – o di altri strumenti di tracciamento – da parte di questo Sito Web o dei titolari dei servizi
								terzi utilizzati da questo Sito Web, ove non diversamente precisato, ha la finalità di fornire il Servizio richiesto dall’Utente,
								oltre alle ulteriori finalità descritte nel presente documento e nella Cookie Policy, se disponibile.
								L’Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi mediante
								questo Sito Web e garantisce di avere il diritto di comunicarli o diffonderli, liberando il Titolare da qualsiasi responsabilità verso terzi.
							</p>
						</div>


						{/* MODALITà raccoglimento DATI RACCOLTI */}
						<div className="mb-6 lg:pr-5">
							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-3xl  text-gray-900 sm:text-4xl sm:leading-none">
									Modalità del raccoglimento dei dati
								</h2>
							</div>
							<p className="mb-4 text-base text-gray-700">
								Il Titolare tratta i Dati Personali degli Utenti adottando le opportune misure
								di sicurezza volte ad impedire l’accesso, la divulgazione, la modifica o la distruzione
								non autorizzate dei Dati Personali. I dati personali potranno essere trattati a mezzo sia di archivi
								cartacei che informatici (ivi compresi dispositivi portati) e trattati con modalità strettamente
								necessarie a far fronte alle finalità sopra indicate. Oltre al Titolare, in alcuni casi,
								potrebbero avere accesso ai Dati categorie di incaricati coinvolti nell’organizzazione del sito
								(personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero
								soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider,
								società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento
								da parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento.									</p>
						</div>



						{/* BAse giuridica */}
						<div className="mb-6 lg:pr-5">
							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-3xl  text-gray-900 sm:text-4xl sm:leading-none">
									Base giuridica del trattamento
								</h2>
							</div>
							<p className="mb-4 text-base text-gray-700">
								Il Titolare tratta Dati Personali relativi all’Utente in caso sussista una delle seguenti condizioni:
								<ul>
									<li className='list-disc'>l’Utente ha prestato il consenso per una o più
										finalità specifiche; Nota: in alcuni ordinamenti il Titolare
										può essere autorizzato a trattare Dati Personali senza che debba
										sussistere il consenso dell’Utente o un’altra delle basi giuridiche
										specificate di seguito, fino a quando l’Utente non si opponga (“opt-out”) a tale trattamento.
										Ciò non è tuttavia applicabile qualora il trattamento di Dati Personali
										sia regolato dalla legislazione europea in materia di protezione dei Dati Personali;
									</li>
									<li className='list-disc'> il trattamento è necessario all’esecuzione
										di un contratto con l’Utente e/o all’esecuzione
										di misure precontrattuali;
									</li>
									<li className='list-disc'>
										il trattamento è necessario per adempiere un
										obbligo legale al quale è soggetto il Titolare;
									</li>
									<li className='list-disc'>
										il trattamento è necessario per l’esecuzione di
										un compito di interesse pubblico o per l’esercizio
										di pubblici poteri di cui è investito il Titolare;
									</li>
									<li className='list-disc'>
										il trattamento è necessario per il perseguimento del
										legittimo interesse del Titolare o di terzi
									</li>
								</ul>
								È comunque sempre possibile richiedere al Titolare di chiarire
								la concreta base giuridica di ciascun trattamento ed in particolare di specificare
								se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto
							</p>
						</div>


						{/* Luogo */}
						<div className="mb-6 lg:pr-5">
							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-3xl  text-gray-900 sm:text-4xl sm:leading-none">
									Luogo
								</h2>
							</div>
							<p className="mb-4 text-base text-gray-700">
								I Dati sono trattati presso le sedi operative del Titolare
								ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate.
								Per ulteriori informazioni, contatta il Titolare.
								I Dati Personali dell’Utente potrebbero essere trasferiti
								in un paese diverso da quello in cui l’Utente si trova.
								Per ottenere ulteriori informazioni sul luogo del trattamento l’Utente può fare riferimento alla sezione relativa ai dettagli sul trattamento dei Dati Personali.
								L’Utente ha diritto a ottenere informazioni in merito alla base giuridica del
								trasferimento di Dati al di fuori dell’Unione Europea o ad un’organizzazione
								internazionale di diritto internazionale pubblico o costituita da due o più paesi,
								come ad esempio l’ONU, nonché in merito alle misure di sicurezza adottate dal Titolare per proteggere i Dati.
								L’Utente può verificare se abbia luogo uno dei trasferimenti appena descritti
								esaminando la sezione di questo documento relativa ai dettagli sul trattamento di Dati Personali o chiedere informazioni al Titolare contattandolo agli estremi riportati in apertura.
							</p>
						</div>

						{/* PERIODO DI CONSERVAZIONE */}
						<div className="mb-6 lg:mb-4 lg:pr-5">
							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-3xl  text-gray-900 sm:text-4xl sm:leading-none">
									Periodo di conservazione
								</h2>
							</div>
							<p className="mb-4 text-base text-gray-700">
								I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati raccolti.
								<br />Pertanto:
								<ul>
									<li className='list-disc'>
										i Dati Personali raccolti per scopi collegati
										all’esecuzione di un contratto tra il Titolare e l’Utente
										saranno trattenuti sino a quando sia completata l’esecuzione di tale contratto;
									</li>
									<li className='list-disc'>
										i Dati Personali raccolti per finalità
										riconducibili all’interesse legittimo del Titolare
										saranno trattenuti sino al soddisfacimento di tale interesse.
										L’Utente può ottenere ulteriori informazioni in merito
										all’interesse legittimo perseguito dal Titolare nelle relative
										sezioni di questo documento o contattando il Titolare.
									</li>

								</ul>

								<br />
								Quando il trattamento è basato sul consenso dell’Utente, il Titolare può
								conservare i Dati Personali più a lungo sino a quando detto consenso non
								venga revocato. Inoltre, il Titolare potrebbe essere obbligato a conservare
								i Dati Personali per un periodo più lungo in ottemperanza ad un obbligo di
								legge o per ordine di un’autorità.
								<br />
								Al termine del periodo di conservazione i Dati Personali saranno cancellati.
								Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione,
								rettificazione ed il diritto alla portabilità dei Dati non potranno più essere esercitati.

							</p>
						</div>

						{/* FINALITà del Trattamento */}
						<div className="mb-6 lg:mb-4 lg:pr-5">
							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-3xl  text-gray-900 sm:text-4xl sm:leading-none">
									Finalità del trattamento dei dati raccolti
								</h2>
							</div>
							<p className="mb-4 text-base text-gray-700">
								I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati raccolti.
								<br />Pertanto:
								<br />
								I Dati dell’Utente sono raccolti per consentire al Titolare di fornire i
								propri servizi, così come per le seguenti finalità: Statistica, Contattare
								l’Utente, Visualizzazione di contenuti da piattaforme esterne.
								Le tipologie di Dati Personali utilizzati per ciascuna finalità sono
								indicati nelle sezioni specifiche di questo documento.
							</p>
						</div>


						{/* DETTAGLI SUL TRATTAMENTO DEI DATI PERSONALI */}
						<div className="mb-6 lg:mb-4 lg:pr-5">
							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="mb-4 font-roboto font-bold tracking-tight text-3xl  text-gray-900 sm:text-4xl sm:leading-none">
									Dettagli sul trattamento dei dati personali
								</h2>
								<p className="mb-6 font-roboto font-bold tracking-tight text-lg  text-gray-900 sm:leading-none">
									I Dati Personali sono raccolti per le seguenti finalità ed utilizzando i seguenti servizi:

								</p>
							</div>
							<div className="mb-6 lg:mb-4 lg:pr-5">
								<div className="mb-6 lg:mb-4 lg:pr-5">
									<h2 className="font-roboto font-bold tracking-tight text-2xl  text-gray-900 sm:leading-none">
										Contattare l'Utente:
									</h2>
								</div>
								<p className="mb-4 text-base text-gray-700">
									<span className="mb-4 text-gray-900 text-lg">
										Modulo di contatto fornito nella pagina "Contatti" di questo sito web</span>
									<br />
									L’Utente, compilando con i propri Dati il modulo di contatto, acconsente al loro utilizzo
									per rispondere alle richieste di informazioni, di preventivo, o di qualunque altra natura
									indicata dall’intestazione del modulo.
									Dati Personali raccolti: Nome, Cognome, Indirizzo email e messaggio.
								</p>
								<p className="mb-4 text-base text-gray-700">
									<span className="mb-4 text-gray-900 text-lg">
										Gestione indirizzi e invio di messaggi email
									</span>
									<br />
									Questi servizi consentono di gestire un database di
									contatti email, o contatti di qualunque altro tipo, utilizzati per comunicare con l’Utente.
									Questi servizi potrebbero inoltre consentire di raccogliere dati relativi alla data e all’ora
									di visualizzazione dei messaggi da parte dell’Utente, così come all’interazione dell’Utente con essi,
									come le informazioni sui click sui collegamenti inseriti nei messaggi. I dati personali raccolti sono l'indirizzo email.
								</p>
							</div>

							<div className="mb-6 lg:mb-4 lg:pr-5">
								<div className="mb-6 lg:mb-4 lg:pr-5">
									<h2 className="font-roboto font-bold tracking-tight text-2xl  text-gray-900 sm:leading-none">
										Statistica:
									</h2>
								</div>
								<p className="mb-4 text-base text-gray-700">
									I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell’Utente.
								</p>
								<p className="mb-4 text-base text-gray-700">
									<span className="mb-4 text-gray-900 text-lg">
										Google Analytics (Google Inc.)
									</span>
									<br />
									Google Analytics è un servizio di analisi web fornito da Google Inc. (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questo Sito Web, compilare report e condividerli con gli altri servizi sviluppati da Google.
									Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.
									Dati Personali raccolti: Cookie; Dati di utilizzo.
									Luogo del trattamento: Stati Uniti – <a href="https://policies.google.com/privacy?hl=it">Privacy Policy Google Analytics</a> . Soggetto aderente al Privacy Shield.
									<br />
									Tool per browser per la disattivazione dei Google Analytics <a href="https://tools.google.com/dlpage/gaoptout?hl=it" className="mb-4 text-blue-500 underline">Opt out</a>


								</p>
							</div>

							<div className="mb-6 lg:mb-4 lg:pr-5">
								<div className="mb-6 lg:mb-4 lg:pr-5">
									<h2 className="font-roboto font-bold tracking-tight text-2xl  text-gray-900 sm:leading-none">
										Visualizzione di contenuti da parte di piattaforme esterne:
									</h2>
								</div>
								<p className="mb-4 text-base text-gray-700">
									Questo tipo di servizi permette di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine di questo Sito Web e di interagire con essi.
									<br />
									Nel caso in cui sia installato un servizio di questo tipo, è possibile che, anche nel caso gli Utenti non utilizzino il servizio, lo stesso raccolga dati di traffico relativi alle pagine in cui è installato.								</p>
								<p className="mb-4 text-base text-gray-700">
									<span className="mb-4 text-gray-900 text-lg">
										Widget Google Maps (Google Inc.)
									</span>
									<br />
									Google Maps è un servizio di visualizzazione di mappe gestito da Google Inc. che permette a questo Sito Web di integrare tali contenuti all’interno delle proprie pagine.
									<br />Dati Personali raccolti: Cookie; Dati di utilizzo.
									Luogo del trattamento: Stati Uniti – <a href="https://policies.google.com/privacy?hl=it">Privacy Policy Google</a> . Soggetto aderente al Privacy Shield.
								</p>
								<p className="mb-4 text-base text-gray-700">
									<span className="mb-4 text-gray-900 text-lg">
										Accesso all’account Facebook (Plugin Facebook)
									</span>
									<br />
									Questo servizio permette a questa Applicazione di connettersi con l’account dell’Utente sul social network Facebook,
									fornito da Facebook, Inc.
									<br />
									Permessi richiesti: Condivisione, Insight e Mi Piace.
									<br />
									Luogo del trattamento: Stati Uniti – <a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0">Privacy Policy Google</a> . Soggetto aderente al Privacy Shield.
								</p>
								<p className="mb-4 text-base text-gray-700">
									<span className="mb-4 text-gray-900 text-lg">
										Accesso all’account Instagram (Plugin Instagram)
									</span>
									<br />
									Questo servizio permette a questa Applicazione di connettersi con l’account dell’Utente sul social network Instagram,
									fornito da Facebook, Inc.
									<br />
									Permessi richiesti: Condivisione, Insight e Mi Piace.
									<br />
									Luogo del trattamento: Stati Uniti – <a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0">Privacy Policy Google</a> . Soggetto aderente al Privacy Shield.
								</p>
							</div>
						</div>


						{/* DIRITTI DELL'UTENTE */}
						<div className="mb-6 lg:pr-5">
							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-3xl  text-gray-900 sm:text-4xl sm:leading-none">
									Diritti dell'utente
								</h2>
							</div>
							<p className="mb-4 text-base text-gray-700">
								Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare.
								In particolare, l’Utente ha il diritto di:
								<ul>
									<li className='list-disc'>
										revocare il consenso in ogni momento. L’Utente può revocare il consenso al trattamento dei propri Dati Personali precedentemente espresso.
									</li>
									<li className='list-disc'>
										opporsi al trattamento dei propri Dati.
										L’Utente può opporsi al trattamento dei propri Dati
										quando esso avviene su una base giuridica diversa dal consenso.
										Ulteriori dettagli sul diritto di opposizione sono indicati nella sezione sottostante.
									</li>
									<li className='list-disc'>
										accedere ai propri Dati. L’Utente ha
										diritto ad ottenere informazioni sui Dati trattati dal Titolare,
										su determinati aspetti del trattamento ed a ricevere una copia dei Dati trattati.
									</li>
									<li className='list-disc'>
										verificare e chiedere la rettificazione. L’Utente può verificare la correttezza dei propri Dati e richiederne l’aggiornamento o la correzione.
									</li>
									<li className='list-disc'>
										ottenere la limitazione del trattamento. Quando ricorrono determinate condizioni, l’Utente può richiedere la limitazione del trattamento dei propri Dati. In tal caso il Titolare non tratterà i Dati per alcun altro scopo se non la loro conservazione.
									</li>
									<li className='list-disc'>
										ottenere la cancellazione o rimozione dei propri Dati Personali. Quando ricorrono determinate condizioni, l’Utente può richiedere la cancellazione dei propri Dati da parte del Titolare.
									</li>
									<li className='list-disc'>
										ricevere i propri Dati o farli trasferire ad altro titolare. L’Utente ha diritto di ricevere i propri Dati in formato strutturato, di uso comune e leggibile da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli ad un altro titolare. Questa disposizione è applicabile quando i Dati sono trattati con strumenti automatizzati ed il trattamento è basato sul consenso dell’Utente, su un contratto di cui l’Utente è parte o su misure contrattuali ad esso connesse.									</li>
									<li className='list-disc'>
										proporre reclamo. L’Utente può proporre un reclamo all’autorità di controllo della protezione dei dati personali competente o agire in sede giudiziale.									</li>
								</ul>
								È comunque sempre possibile richiedere al Titolare di chiarire
								la concreta base giuridica di ciascun trattamento ed in particolare di specificare
								se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto
							</p>
						</div>


						{/* Dettagli sul diritto di opposizione */}
						<div className="mb-6 lg:mb-4 lg:pr-5">
							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-3xl  text-gray-900 sm:text-4xl sm:leading-none">
									Dettagli sul diritto di opposizione
								</h2>
							</div>
							<div className="">
								<p className="mb-4 text-base text-gray-700">
									Quando i Dati Personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici poteri di cui è investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.
									Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto, possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il Titolare tratti dati con finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo documento.
								</p>
							</div>
						</div>



						{/* Come esercitare i diritti */}
						<div className="mb-6 lg:mb-4 lg:pr-5">
							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-3xl  text-gray-900 sm:text-4xl sm:leading-none">
									Come esercitare i diritti
								</h2>
							</div>
							<div className="">
								<p className="mb-4 text-base text-gray-700">
									Quando i Dati Personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici poteri di cui è investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.
									Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto, possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il Titolare tratti dati con finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo documento.
								</p>
							</div>
						</div>


						{/* DIRITTI DELL'UTENTE */}
						<div className="mb-6 lg:pr-5">
							<div className="mb-6 lg:mb-4 mt-8 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-4xl  text-gray-900 sm:text-6xl sm:leading-none">
									Cookie policy
								</h2>
							</div>
							<p className="mb-4 text-base text-gray-700">
								Con riferimento a molteplici ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679, si dichiara che:
								<ul>
									<li className='list-decimal'>
										Il nostro sito web (più avanti “Sito”) utilizza i cookie per offrire i propri servizi agli Utenti durante la consultazione delle sue pagine.
									</li>
									<li className='list-decimal'>
										L’informativa è valida solo per questo Sito e per gli eventuali domini di secondo e terzo livello correlati, e non per altri siti consultabili tramite link.									</li>
									<li className='list-decimal'>
										Il nostro sito web (più avanti “Sito”) utilizza i cookie per offrire i propri servizi agli Utenti durante la consultazione delle sue pagine.
									</li>
									<li className='list-decimal'>
										Se l’utente non acconsente all’utilizzo dei cookie, non accettando in maniera esplicita i cookie di navigazione, o mediante specifiche configurazioni del browser utilizzato o dei relativi programmi informatici utilizzati per navigare le pagine che compongono il Sito, o modificando le impostazioni nell’uso dei servizi di terze parti utilizzati all’interno del Sito, l’esperienza di navigazione potrebbe essere penalizzata, ed alcune funzionalità potrebbero non essere disponibili.									</li>
									<li className='list-decimal'>
										Il nostro sito web (più avanti “Sito”) utilizza i cookie per offrire i propri servizi agli Utenti durante la consultazione delle sue pagine.
									</li>
									<li className='list-decimal'>
										Il Sito NON fa uso diretto (first-part cookie) di cookie di PROFILAZIONE degli utenti.
									</li>
									<li className='list-decimal'>
										Il Sito NON consente l’invio di cookie di PROFILAZIONE di TERZE PARTI (third-part cookie).
									</li>
									<li className='list-decimal'>
										Il Sito fa uso diretto esclusivamente di cookie TECNICI per salvare i parametri di sessione e agevolare quindi la navigazione agli utenti.
									</li>
									<li className='list-decimal'>
										il Sito potrà fare uso di cookie TECNICI di terze parti (non tutti i servizi sono per forza attivi):
									</li>

								</ul>
								<p className="mb-4 text-base text-gray-700">
									<span className="mb-4 text-gray-900 text-lg">
										Google
									</span>
									<br />
									Il servizio Google Analytics viene utilizzato per raccogliere statistiche ANONIME di accesso, monitorare e analizzare i dati di traffico.
									I servizi Google Maps e YouTube sono utilizzati per includere contenuti multimediali all’interno del sito.
									<br />
									<a href="https://policies.google.com/privacy?hl=it">Privacy Policy Google</a> . Soggetto aderente al Privacy Shield.
								</p>
								<p className="mb-4 text-base text-gray-700">
									<span className="mb-4 text-gray-900 text-xl uppercase">
										Bottoni social
									</span>
									<br />
									I bottoni social sono bottoni che permettono di rendere più immediata ed agevole la condivisione dei contenuti sulle più comuni piattaforme social. Qui di seguito i dettagli dei principali servizi:
									<br />
									<a href="https://policies.google.com/privacy?hl=it">Privacy Policy Google</a> . Soggetto aderente al Privacy Shield.
								</p>
								<p className="mb-4 text-base text-gray-700">
									<span className="mb-4 text-gray-900 text-lg">
										Pulsante “Mi Piace” e widget sociali di Facebook (Facebook, Inc.)
									</span>
									<br />
									Il pulsante “Mi Piace” e i widget sociali di Facebook sono servizi di interazione con il social network Facebook, forniti da Facebook, Inc.
									Dati personali raccolti: Cookie e Dati di utilizzo.

									<br />
									<a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0">Privacy Policy</a> . Soggetto aderente al Privacy Shield.
								</p>
								<p className="mb-4 text-base text-gray-700">
									<span className="mb-4 text-gray-900 text-lg">
										Pulsante “Segui” e widget sociali di Instagram (Facebook, Inc.)
									</span>
									<br />
									Il pulsante “Segui” e i widget sociali di Instagram sono servizi di interazione con il social network Instagram, forniti da Facebook, Inc.
									Dati personali raccolti: Cookie e Dati di utilizzo.
									<br />
									<a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0">Privacy Policy</a> . Soggetto aderente al Privacy Shield.
								</p>
							</p>
						</div>

						{/* Cookies utilizzati su questo sito */}
						<div className="mb-6 lg:mb-4 lg:pr-5">
							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-3xl  text-gray-900 sm:text-4xl sm:leading-none">
									Cookies utilizzati su questo sito
								</h2>
								<p className="mb-4 text-base text-gray-700">
									Questo sito web fa uso di cookie tecnici ‘di sessione’, persistenti e di Terze Parti. Non fa uso di cookie di profilazione.
								</p>
							</div>
							<div className="mb-6 lg:mb-4 lg:pr-5">
								<div className="mb-6 lg:mb-4 lg:pr-5">
									<h2 className="font-roboto font-bold tracking-tight text-2xl  text-gray-900 sm:leading-none">
										Statistica:
									</h2>
								</div>
								<p className="mb-4 text-base text-gray-700">
									I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell’Utente.
								</p>
								<p className="mb-4 text-base text-gray-700">
									<span className="mb-4 text-gray-900 text-lg">
										Google Analytics (Google Inc.)
									</span>
									<br />
									Google Analytics è un servizio di analisi web fornito da Google Inc. (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questo Sito Web, compilare report e condividerli con gli altri servizi sviluppati da Google.
									Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.
									Dati Personali raccolti: Cookie; Dati di utilizzo.
									Luogo del trattamento: Stati Uniti – <a href="https://policies.google.com/privacy?hl=it">Privacy Policy Google Analytics</a> . Soggetto aderente al Privacy Shield.
									<br />
									Tool per browser per la disattivazione dei Google Analytics <a href="https://tools.google.com/dlpage/gaoptout?hl=it" className="mb-4 text-blue-500 underline">Opt out</a>
								</p>
							</div>
							<div className="mb-6 lg:mb-4 lg:pr-5">
								<div className="mb-6 lg:mb-4 lg:pr-5">
									<h2 className="font-roboto font-bold tracking-tight text-2xl  text-gray-900 sm:leading-none">
										Visualizzione di contenuti da parte di piattaforme esterne:
									</h2>
								</div>
								<p className="mb-4 text-base text-gray-700">
									Questo tipo di servizi permette di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine di questo Sito Web e di interagire con essi.
									<br />
									Nel caso in cui sia installato un servizio di questo tipo, è possibile che, anche nel caso gli Utenti non utilizzino il servizio, lo stesso raccolga dati di traffico relativi alle pagine in cui è installato.								</p>
								<p className="mb-4 text-base text-gray-700">
									<span className="mb-4 text-gray-900 text-lg">
										Widget Google Maps (Google Inc.)
									</span>
									<br />
									Google Maps è un servizio di visualizzazione di mappe gestito da Google Inc. che permette a questo Sito Web di integrare tali contenuti all’interno delle proprie pagine.
									<br />Dati Personali raccolti: Cookie; Dati di utilizzo.
									Luogo del trattamento: Stati Uniti – <a href="https://policies.google.com/privacy?hl=it">Privacy Policy Google</a> . Soggetto aderente al Privacy Shield.
								</p>
								<p className="mb-4 text-base text-gray-700">
									<span className="mb-4 text-gray-900 text-lg">
										Accesso all’account Facebook (Plugin Facebook)
									</span>
									<br />
									Questo servizio permette a questa Applicazione di connettersi con l’account dell’Utente sul social network Facebook,
									fornito da Facebook, Inc.
									<br />
									Permessi richiesti: Condivisione, Insight e Mi Piace.
									<br />
									Luogo del trattamento: Stati Uniti – <a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0">Privacy Policy Google</a> . Soggetto aderente al Privacy Shield.
								</p>
								<p className="mb-4 text-base text-gray-700">
									<span className="mb-4 text-gray-900 text-lg">
										Accesso all’account Instagram (Plugin Instagram)
									</span>
									<br />
									Questo servizio permette a questa Applicazione di connettersi con l’account dell’Utente sul social network Instagram,
									fornito da Facebook, Inc.
									<br />
									Permessi richiesti: Condivisione, Insight e Mi Piace.
									<br />
									Luogo del trattamento: Stati Uniti – <a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0">Privacy Policy Google</a> . Soggetto aderente al Privacy Shield.
								</p>
							</div>
						</div>


						{/* Cookies utilizzati su questo sito */}
						<div className="mb-6 lg:mb-4 lg:pr-5">
							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-3xl  text-gray-900 sm:text-4xl sm:leading-none">
									Come controllare la gestione dei cookie su questo sito

								</h2>
								<p className="mb-4 text-base text-gray-700">
									Le modalità di funzionamento nonché le opzioni per limitare o bloccare i cookie, possono essere effettuate modificando le impostazioni del proprio browser Internet. È possibile inoltre visitare il sito, in lingua inglese, www.aboutcookies.org per informazioni su come poter gestire/eliminare i cookie in base al tipo di browser utilizzato.
									<br />
									Tutti i moderni browser offrono la possibilità di controllare le impostazioni di privacy, anche per quello che riguarda l’uso dei cookie. In particolare l’utente potrà intervenire sul comportamento generale del browser nei confronti dei cookie (ad esempio istruendolo a NON accettarli in futuro), visualizzare e/o cancellare i cookie già installati.
									<br />
									Riportiamo qui di seguito le procedure per accedere a queste impostazioni per i browser più utilizzati:

								</p>
							</div>
							<ul>
								<li className='list-desc'>
									<a href="https://support.google.com/chrome/answer/95647?hl=it">Chrome</a>
								</li>
								<li className='list-desc'>
									<a href="https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d">internet Explorer</a>
								</li>
								<li className='list-desc'>
									<a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">FireFox</a>
								</li>
								<li className='list-desc'>
									<a href="https://help.opera.com/en/latest/web-preferences/">Opera</a>
								</li>
								<li className='list-desc'>
									<a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac">Safari 6/7</a>
								</li>
								<li className='list-desc'>
									<a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac">Safari 8</a>
								</li>
								<li className='list-desc'>
									<a href="https://support.apple.com/en-us/HT201265">Safari Mobile</a>
								</li>

							</ul>
							<br />
							<p>
								E’ possibile anche utilizzare le seguenti istruzioni per disabilitare i cookie:
								<br />
								Se si utilizza Internet Explorer
								In Internet Explorer, fare clic su “Strumenti” poi “Opzioni Internet”. Nella scheda Privacy, spostare il cursore verso l’alto per bloccare tutti i cookie o verso il basso per consentire a tutti i cookie, e quindi fare clic su OK.
								<br />
								Se si utilizza il browser Firefox
								Vai al menu “Strumenti” del browser e selezionare il menu “Opzioni” Fare clic sulla scheda “Privacy”, deselezionare la casella “Accetta cookie” e fare clic su OK.
								<br />
								Se si utilizza il browser Safari
								Dal Browser Safari selezionare il menu “Modifica” e selezionare “Preferences”. Clicca su “Privacy”. Posizionare l’impostazione “cookies Block” sempre “e fare clic su OK.
								<br />
								Se si utilizza il browser Google Chrome
								Fai clic sul menu Chrome nella barra degli strumenti del browser. Selezionare “Impostazioni”. Fare clic su “Mostra impostazioni avanzate”. Nella sezione “Privacy”, fai clic sul pulsante “Impostazioni contenuti”. Nella sezione “Cookies”, selezionare “Non consentire ai siti per memorizzare i dati” e di controllo “cookie di blocco e di terze parti i dati sito”, e quindi fare clic su OK.
								<br />
								Se usi un qualsiasi altro browser, cerca nelle Impostazioni del browser la modalità di gestione dei cookies.
								Se desideri eliminare i file cookie eventualmente già presenti sul tuo computer, leggi le istruzioni del tuo browser cliccando sulla voce “Aiuto” del relativo menu. Puoi anche trovare ulteriori informazioni sui cookies e su come gestirli consultando il sito www.aboutcookies.org o cliccando su “Aiuto” nel menu del tuo browser oppure consultando la legge “Individuazione delle modalità semplificate per l’informativa e l’acquisizione del consenso per l’uso dei cookie” dell’8 maggio 2014 (Pubblicato sulla Gazzetta Ufficiale n. 126 del 3 giugno 2014)
								<br />
								Ti consigliamo anche di consultare il sito Your Online Choices dove potrai bloccare alcuni cookie e trovare interessanti informazioni.
								Per eliminare i cookie dal browser Internet del proprio smartphone/tablet, è necessario fare riferimento al manuale d’uso del dispositivo.</p>
						</div>


						{/* ulteriori info */}
						<div className="mb-6 lg:pr-5">
							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-3xl  text-gray-900 sm:text-4xl sm:leading-none">
									Ulteriori informazioni
								</h2>
							</div>


							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-xl  text-gray-900 sm:text-2xl sm:leading-none">
									Difesa in giudizio
								</h2>
								<p className="mb-4 text-base text-gray-700">
									I Dati Personali dell’Utente possono essere utilizzati per la difesa da parte del Titolare in giudizio o nelle fasi propedeutiche alla sua eventuale instaurazione, da abusi nell’utilizzo della stessa o dei servizi connessi da parte dell’Utente. L’Utente dichiara di essere consapevole che il Titolare potrebbe essere richiesto di rivelare i Dati su richiesta delle pubbliche autorità.
								</p>
							</div>




							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-xl  text-gray-900 sm:text-2xl sm:leading-none">
									Informative specifiche
								</h2>
								<p className="mb-4 text-base text-gray-700">
									Su richiesta dell’Utente, in aggiunta alle informazioni contenute in questa privacy policy, questo Sito Web potrebbe fornire all’Utente delle informative aggiuntive e contestuali riguardanti Servizi specifici, o la raccolta ed il trattamento di Dati Personali.
								</p>
							</div>




							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-xl  text-gray-900 sm:text-2xl sm:leading-none">
									Log di sistema e manutenzione
								</h2>
								<p className="mb-4 text-base text-gray-700">
									Per necessità legate al funzionamento ed alla manutenzione, questo Sito Web e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che possono contenere anche Dati Personali, quali l’indirizzo IP Utente.
								</p>
							</div>





							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-xl  text-gray-900 sm:text-2xl sm:leading-none">
									Informazioni non contenute in questa policy
								</h2>
								<p className="mb-4 text-base text-gray-700">
									Maggiori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in qualsiasi momento al Titolare del Trattamento utilizzando le informazioni di contatto.
								</p>
							</div>



							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-xl  text-gray-900 sm:text-2xl sm:leading-none">
									Dati forniti volontariamente dall’utente
								</h2>
								<p className="mb-4 text-base text-gray-700">
									L’invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati su questo sito comporta la successiva acquisizione dell’indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati personali inseriti nella missiva. Specifiche informative di sintesi verranno progressivamente riportate o visualizzate nelle pagine del sito predisposte per particolari servizi a richiesta.
								</p>
							</div>






							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-xl  text-gray-900 sm:text-2xl sm:leading-none">
									Facoltatività del conferimento dei dati
								</h2>
								<p className="mb-4 text-base text-gray-700">
									A parte quanto specificato per i dati di navigazione, l’utente è libero di fornire i dati personali riportati nei moduli di richiesta al Garante o comunque indicati in contatti con l’Ufficio per sollecitare l’invio di materiale informativo o di altre comunicazioni. Il loro mancato conferimento può comportare l’impossibilità di ottenere quanto richiesto. Per completezza va ricordato che in alcuni casi (non oggetto dell’ordinaria gestione di questo sito) l’Autorità può richiedere notizie e informazioni ai sensi dell’art 157 del d.lgs. n. 196/2003, ai fini del controllo sul trattamento dei dati personali. In questi casi la risposta è obbligatoria a pena di sanzione amministrativa.
								</p>
							</div>


							<div className="mb-6 lg:mb-4 lg:pr-5">
								<h2 className="font-roboto font-bold tracking-tight text-xl  text-gray-900 sm:text-2xl sm:leading-none">
									Modifiche a questa privacy policy
								</h2>
								<p className="mb-4 text-base text-gray-700">
									Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento dandone pubblicità agli Utenti su questa pagina. Si prega dunque di consultare spesso questa pagina, prendendo come riferimento la data di ultima modifica indicata in fondo. Nel caso di mancata accettazione delle modifiche apportate alla presente privacy policy, l’Utente è tenuto a cessare l’utilizzo di questa Applicazione e può richiedere al Titolare del Trattamento di rimuovere i propri Dati Personali. Salvo quanto diversamente specificato, la precedente privacy policy continuerà ad applicarsi ai Dati Personali sino a quel momento raccolti.
									<br />
									Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il Titolare provvederà a raccogliere nuovamente il consenso dell’Utente, se necessario.
								</p>
							</div>

						</div>


						<p className="mb-4 text-base text-gray-700">
							Questo documento è stato aggiornato il 5 settembre 2022 con valenza annuale.

						</p>









					</div>
				</div>
			</div>
		</LayoutPrivacyPolicy >
	)
};


export default PrivacyPolicy;
