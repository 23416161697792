import React from 'react';
import Footer from './Footer';
import CustomHeader from './CustomHeader';
import Banner from './Banner';
import { ReactCookieConsent } from './ReactCookieConsent';

export const LayoutPrivacyPolicy = ({ children }) => {

  return (
    <>
      <Banner />
      <CustomHeader />
      <main className="text-gray-900">{children}</main>
      <Footer />
    </>
  );
};

